/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.32/esri/copyright.txt for details.
*/
import "../../core/has.js";
import e from "../../core/Error.js";
import t from "../HeightModelInfo.js";
import { parse as n } from "../../layers/support/arcgisLayerUrl.js";
function i(n, i) {
  if (!n) return null;
  if (!s(n)) return new e("webscene:unsupported-height-model-info", "The vertical coordinate system of the scene is not supported", {
    heightModelInfo: n
  });
  const a = n.heightUnit,
    r = t.deriveUnitFromSR(n, i).heightUnit;
  return a !== r ? new e("webscene:incompatible-height-unit", `The vertical units of the scene (${a}) must match the horizontal units of the scene (${r})`, {
    verticalUnit: a,
    horizontalUnit: r
  }) : null;
}
function a(n, i, a) {
  const s = l(n),
    h = i,
    u = r(s, h, a);
  if (s) {
    const i = t.deriveUnitFromSR(s, n.spatialReference).heightUnit;
    if (!a && i !== s.heightUnit) {
      const t = new e("layerview:unmatched-height-unit", `The vertical units of the layer must match the horizontal units (${i})`, {
        horizontalUnit: i
      });
      return new e("layerview:unsupported-height-model-info", "The vertical coordinate system of the layer is not supported", {
        heightModelInfo: s,
        error: t
      });
    }
  }
  if (!c(n) || u === o.Unsupported) return new e("layerview:unsupported-height-model-info", "The vertical coordinate system of the layer is not supported", {
    heightModelInfo: s
  });
  switch (u) {
    case o.Units:
      {
        const t = s?.heightUnit || "unknown",
          n = h?.heightUnit || "unknown",
          i = new e("layerview:incompatible-height-unit", `The vertical units of the layer (${t}) must match the vertical units of the scene (${n})`, {
            layerUnit: t,
            sceneUnit: n
          });
        return new e("layerview:incompatible-height-model-info", "The vertical coordinate system of the layer is incompatible with the scene", {
          layerHeightModelInfo: s,
          sceneHeightModelInfo: h,
          error: i
        });
      }
    case o.HeightModel:
      {
        const t = s?.heightModel || "unknown",
          n = h?.heightModel || "unknown",
          i = new e("layerview:incompatible-height-model", `The height model of the layer (${t}) must match the height model of the scene (${n})`, {
            layerHeightModel: t,
            sceneHeightModel: n
          });
        return new e("layerview:incompatible-height-model-info", "The vertical coordinate system of the layer is incompatible with the scene", {
          layerHeightModelInfo: s,
          sceneHeightModelInfo: h,
          error: i
        });
      }
    case o.CRS:
      {
        const t = s?.vertCRS || "unknown",
          n = h?.vertCRS || "unknown",
          i = new e("layerview:incompatible-vertical-datum", `The vertical datum of the layer (${t}) must match the vertical datum of the scene (${n})`, {
            layerDatum: t,
            sceneDatum: n
          });
        return new e("layerview:incompatible-height-model-info", "The vertical coordinate system of the layer is incompatible with the scene", {
          layerHeightModelInfo: s,
          sceneHeightModelInfo: h,
          error: i
        });
      }
  }
  return null;
}
function r(e, t, n) {
  if (!s(e) || !s(t)) return o.Unsupported;
  if (null == e || null == t) return o.Ok;
  if (!n && e.heightUnit !== t.heightUnit) return o.Units;
  if (e.heightModel !== t.heightModel) return o.HeightModel;
  switch (e.heightModel) {
    case "gravity-related-height":
      return o.Ok;
    case "ellipsoidal":
      return e.vertCRS === t.vertCRS ? o.Ok : o.CRS;
    default:
      return o.Unsupported;
  }
}
var o;
function s(e) {
  return null == e || null != e.heightModel && null != e.heightUnit;
}
function c(e) {
  return "heightModelInfo" in e && null != e.heightModelInfo || null != e.spatialReference || !d(e);
}
function l(e) {
  if ("integrated-mesh-3dtiles" === e.type) return null;
  const i = e.url ? n(e.url) : void 0,
    a = e.spatialReference?.vcsWkid;
  return !(null == a && null != i && "ImageServer" === i.serverType) && h(e) && e.heightModelInfo ? e.heightModelInfo : d(e) ? t.deriveUnitFromSR(p, e.spatialReference) : null;
}
function h(e) {
  return "heightModelInfo" in e;
}
function u(e) {
  if ("unknown" === e.type || !("capabilities" in e)) return !1;
  switch (e.type) {
    case "catalog":
    case "catalog-footprint":
    case "csv":
    case "feature":
    case "geojson":
    case "subtype-group":
    case "ogc-feature":
    case "oriented-imagery":
    case "wfs":
    case "knowledge-graph-sublayer":
      return !0;
    default:
      return !1;
  }
}
function d(e) {
  return u(e) ? !!(e.capabilities && e.capabilities.data && e.capabilities.data.supportsZ) : f(e);
}
function g(e) {
  return null != e.layers || f(e) || u(e) || h(e);
}
function f(e) {
  switch (e.type) {
    case "building-scene":
    case "elevation":
    case "integrated-mesh":
    case "integrated-mesh-3dtiles":
    case "point-cloud":
    case "scene":
    case "voxel":
      return !0;
    case "base-dynamic":
    case "base-elevation":
    case "base-tile":
    case "bing-maps":
    case "catalog":
    case "catalog-footprint":
    case "catalog-dynamic-group":
    case "csv":
    case "dimension":
    case "geojson":
    case "feature":
    case "subtype-group":
    case "geo-rss":
    case "graphics":
    case "group":
    case "imagery":
    case "imagery-tile":
    case "kml":
    case "knowledge-graph":
    case "link-chart":
    case "knowledge-graph-sublayer":
    case "line-of-sight":
    case "map-image":
    case "map-notes":
    case "media":
    case "ogc-feature":
    case "open-street-map":
    case "oriented-imagery":
    case "parquet":
    case "route":
    case "stream":
    case "tile":
    case "unknown":
    case "unsupported":
    case "vector-tile":
    case "video":
    case "viewshed":
    case "wcs":
    case "web-tile":
    case "wfs":
    case "wms":
    case "wmts":
    case null:
      return !1;
  }
  return !1;
}
!function (e) {
  e[e.Ok = 0] = "Ok", e[e.Units = 1] = "Units", e[e.HeightModel = 2] = "HeightModel", e[e.CRS = 3] = "CRS", e[e.Unsupported = 4] = "Unsupported";
}(o || (o = {}));
const p = new t({
  heightModel: "gravity-related-height"
});
export { l as deriveHeightModelInfoFromLayer, a as rejectLayerError, g as supportsHeightModelInfo, i as validateWebSceneError };